import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { NgbSortableHeader } from "../../../../directives/sortable.directive";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../../account-management/users/users.service";
import { KeyMap } from "../../../../models/shared";

import { LinodeAccountsService } from "../linode-accounts.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { Router } from "@angular/router";

import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import { LinodeAccount } from "@zixi/models";
import { DatePipe } from "@angular/common";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";

@Component({
    selector: "app-linode-account-list",
    templateUrl: "./linode-account-list.component.html",
    providers: [DatePipe]
})
export class LinodeAccountListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    linodeAccounts: LinodeAccount[];

    sortedByText = "NONE";
    isAdmin: boolean;
    urls = Constants.urls;

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;

    private linodeAccountsSubscription: Subscription;

    private linodeAccountsBS$ = new BehaviorSubject<LinodeAccount[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 125,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<LinodeAccount>>(
                (row: KeyMap<LinodeAccount>): string => row?.name,
                (row: KeyMap<LinodeAccount>): string => row?.name,
                (): boolean => true
            ),
            sortBy: (row: KeyMap<LinodeAccount>): string => row?.name,
            textValue: (row: KeyMap<LinodeAccount>): string => row?.name
        },
        {
            header: this.translate.instant("CREATED_AT"),
            columnDef: "created_at",
            width: 220,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<LinodeAccount>>(row =>
                String(row.created_at)
            )
        },
        {
            header: this.translate.instant("UPDATED_AT"),
            columnDef: "updated_at",
            width: 220,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<LinodeAccount>>(row =>
                String(row.updated_at)
            )
        },

        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            align: "right",
            sticky: 3,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<LinodeAccount, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row)
            })
        }
    ];

    constructor(
        private router: Router,
        private ak: LinodeAccountsService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private datePipe: DatePipe
    ) {
        // Set Title
        this.titleService.setTitle("LINODE_ACCOUNTS", "");
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.linodeAccountsSubscription = this.ak.linodeAccounts.subscribe(linodeAccounts => {
            this.linodeAccounts = linodeAccounts;
            if (this.linodeAccounts) {
                this.prepTableData();
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.linodeAccountsSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const linodeAccounts = this.ak.refreshLinodeAccounts().toPromise();
        await Promise.all([linodeAccounts]);

        this.refreshing = false;
    }

    edit(linodeAccount: LinodeAccount): void {
        this.router.navigate([Constants.urls.configuration.linode, linodeAccount.id, "edit"]);
    }

    async delete(linodeAccount: LinodeAccount) {
        await this.modalService.confirm(
            "DELETE",
            "LINODE_ACCOUNT",
            async () => {
                const id = linodeAccount.id;
                const result = await this.ak.deleteLinodeAccount(id);
                if (result) {
                    this.mixpanelService.sendEvent("delete linode account", { id });
                } else return false;
            },
            linodeAccount.name
        );
    }

    get linodeAccounts$() {
        return this.linodeAccountsBS$.asObservable();
    }

    private prepTableData() {
        if (this.linodeAccounts) {
            this.linodeAccountsBS$.next([...this.linodeAccounts]);
        }
    }
}
